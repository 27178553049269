<template>
  <div>
    <ReportAnalisis
    ></ReportAnalisis>
  </div>
</template>

<script>
import ReportAnalisis from "@/components/dashboard/DashboardSalesContrib";
export default {
  name: "AnalisisView",
  components: {ReportAnalisis}
}
</script>

<style scoped>

</style>